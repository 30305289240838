<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :isPending="currentIsPending"
  />
</template>
<script>
import { computed } from "vue-demi";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { useFindByPartId } from "../../utils/feathers-pinia/use-get-helpers";
import { useBuffer } from "../../store/buffer.pinia";
import { useScheduleDocument } from "../../store/scheduleDocument.pinia";
import { useDocument } from "../../store/document.pinia";
import { computedRef } from "../../utils/composables";
import { useFind } from "feathers-pinia";

export default {
  name: "ChainedFlowInitAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    console.warn("ChainedFlowInitAccordion.vue");
    const bufferStore = useBuffer();
    const scheduleDocumentStore = useScheduleDocument();
    const documentStore = useDocument();

    const partId = computed(() => props?.node?.part?.id ?? null);
    const { items: bufferResult, isPending: isPendingBuffer } = useFindByPartId(
      partId,
      bufferStore
    );

    const {
      items: scheduleDocumentResults,
      isPending: isPendingScheduleDocument,
    } = useFindByPartId(props.node?.part?.id, scheduleDocumentStore);

    const documentIds = computedRef(() => {
      return (
        scheduleDocumentResults.value?.map((item) => item.document_id) ?? []
      );
    });
    const documentQuery = computedRef(() => {
      return {
        query: {
          id: { $in: documentIds.value },
          $sort: { name: 1 },
        },
      };
    });
    const { items: documentResults, isPending: isPendingDocument } = useFind({
      model: documentStore.Model,
      params: documentQuery,
      queryWhen: computed(() => !!documentQuery.value?.query.id.$in.length),
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        buffer: isPendingBuffer.value,
        scheduleDocument: isPendingScheduleDocument.value,
        document: isPendingDocument.value,
      };
    });

    const entities = computed(() => {
      return {
        buffer: bufferResult?.value[0],
        scheduleDocument: scheduleDocumentResults.value,
        document: documentResults.value,
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow("Chained Flow Initiation", [
          entityRowField("buffer", "qty_per_handling_unit", {
            label: "Quantity per Handling Unit",
          }),
        ]),
        entityRow(
          "Documents",
          [
            entityRowField("scheduleDocument", null, {
              type: "documents",
              includeEntities: true,
            }),
          ],
          {
            showLabel: true,
          }
        ),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
